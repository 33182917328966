import { Controller } from "stimulus";
import { debounce } from "~/utils/lodashish";

export default class extends Controller {
  static values = {
    url: String,
    variables: Object,
    autoFill: Boolean,
    minimumSearchLength: Number,
    focusedResultIndex: Number,
    followLinkOnEnter: Boolean,
    submitOnSelect: Boolean,
    autoFocus: Boolean,
    entity: String,
    enableSubmitButton: Boolean
  };
  static targets = ["search", "template", "id", "className", "resetButton", "resultsContainer", "container"];

  initialize() {
    this.debounceSearchTargetKeyup = debounce(this.searchTargetKeyup, 500).bind(this);
    this.debounceBlurSearchTargetKeyup = debounce(this.validateInput, 1000).bind(this);
  }

  connect() {
    this.originalValue = this.searchTarget.value;
    this.searchField = this.searchTarget;
    this.searchResultTemplate = this.templateTarget.innerHTML;

    this.element.classList.add("relative");

    if (!this.hasMinimumSearchLengthValue) {
      this.minimumSearchLengthValue = 2;
    }

    this.searchResults = [];

    if (this.hasIdTarget) {
      if (this.idTarget.value.length > 0) {
        this.resetButtonTarget.classList.remove("hidden");
        this.searchTarget.readOnly = true;
      }
    }

    this.searchTarget.addEventListener("blur", this.debounceBlurSearchTargetKeyup);
    this.searchTarget.addEventListener("keyup", this.debounceSearchTargetKeyup);
    this.searchTarget.addEventListener("keydown", this.searchTargetKeydown.bind(this));
    this.searchTarget.addEventListener("focusout", this.closeSearchResultsAfterClickAway.bind(this));
    this.searchTarget.addEventListener("focus", this.unhideSearchResults.bind(this));
    this.searchTarget.addEventListener("focus", this.maybeAutoSearch.bind(this));
    if (this.hasResetButtonTarget) {
      this.resetButtonTarget.addEventListener("keypress", this.submitFromResetButton.bind(this));
    }
    if (this.autoFocusValue) {
      this.searchTarget.focus();
    }
  }

  disconnect() {
    this.searchTarget.removeEventListener("blur", this.debounceBlurSearchTargetKeyup);
    this.searchTarget.removeEventListener("keyup", this.debounceSearchTargetKeyup);
    this.searchTarget.removeEventListener("keydown", this.searchTargetKeydown.bind(this));
    this.searchTarget.removeEventListener("focusout", this.closeSearchResultsAfterClickAway.bind(this));
    this.searchTarget.removeEventListener("focus", this.unhideSearchResults.bind(this));
    this.searchTarget.removeEventListener("focus", this.maybeAutoSearch.bind(this));
    if (this.hasResetButtonTarget) {
      this.resetButtonTarget.removeEventListener("keypress", this.submitFromResetButton.bind(this));
    }
  }

  validateInput = () => {
    if (!this.idTarget.value) {
      if (this.hasSearchTarget) this.searchTarget.value = "";
      this.triggerBlurEvent(this.idTarget);
    }
  };

  closeSearchResultsAfterClickAway() {
    setTimeout(() => this.hideSearchResults(), 400);
  }

  createSearchResult(result, forListing = true) {
    //searchResultTemplate is a string, so adding an empty string will create a new string
    let resultContent = this.searchResultTemplate + "";
    for (const variable in this.variablesValue) {
      resultContent = resultContent.replace(`{{${variable}}}`, result[this.variablesValue[variable]]);
    }

    const resultElement = document.createElement("div");
    resultElement.innerHTML = resultContent;

    if (forListing) {
      resultElement.classList.add(
        "result-item",
        "flex",
        "items-center",
        "gap-2",
        "px-2",
        "py-1.5",
        "hover:bg-dark-50",
        "rounded",
        "cursor-pointer",
        "text-dark-600",
        "mx-1"
      );
      resultElement.dataset.id = `${result.id}`;
      resultElement.dataset.class_name = result.class_name;
      resultElement.dataset.isResult = true;
      resultElement.addEventListener("click", this.selectSearchResultOnClick.bind(this));
    } else {
      resultElement.classList.add("flex-grow", "bg-dark-50", "rounded");
    }

    return resultElement;
  }

  createAddPersonResult(resultsContainer) {
    let action = "createPerson";
    if (resultsContainer.dataset.showNewForm) {
      action = "newCapitalAccountContactPerson";
    }
    const createResult = document.createElement("div");
    createResult.innerHTML = `<a id='btn_new_person' data-action='click->searchable#${action}' class='px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50'><i class='ph-plus-circle-bold text-md'></i>Add Person</a>`;

    return createResult;
  }

  createAddOrganizationResult(resultsContainer) {
    let action = "createOrganization";
    if (resultsContainer.dataset.showNewForm) {
      action = "newCapitalAccountContactOrganization";
    }
    const createResult = document.createElement("div");
    createResult.innerHTML = `<a id='btn_new_org' data-action='click->searchable#${action}' class='px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50'><i class='ph-plus-circle-bold text-md'></i>Add Organization</a>`;

    return createResult;
  }

  createAddAccountingEntityResult() {
    const createResult = document.createElement("div");
    createResult.innerHTML =
      "<a data-action='click->searchable#createAccountingEntity' class='px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50'><i class='ph-plus-circle-bold text-md'></i>Add Entity</a>";

    return createResult;
  }

  createAddPortfolioCompanyResult() {
    const createResult = document.createElement("div");
    createResult.innerHTML =
      "<a data-action='click->searchable#createPortfolioCompany' class='px-4 py-3 text-sm font-bold text-primary-600 flex items-center gap-1.5 cursor-pointer rounded hover:bg-dark-50'><i class='ph-plus-circle-bold text-md'></i>Add Portfolio Company</a>";

    return createResult;
  }

  maybeAutoSearch() {
    if (this.autoFillValue) {
      this.searchTargetKeyup({ keyCode: 0 });
    }
  }

  hideSearchResults() {
    if (!this.resultsContainerTarget) return;
    // If more than one results containers, assume the parent should be hidden
    if (this.resultsContainerTargets.length > 1) {
      this.resultsContainerTarget.parentElement.classList.add("hidden");
    } else {
      this.resultsContainerTarget?.classList?.add("hidden");
    }
  }

  unhideSearchResults() {
    if (this.searchTarget.readOnly || !this.searchTarget.value.length) return;

    const populatedContainer = this.resultsContainerTargets.find((container) => {
      return container.querySelectorAll("div").length > 0;
    });

    if (!populatedContainer) {
      return;
    }

    // If more than one results containers, assume the parent should be shown
    if (this.resultsContainerTargets.length > 1) {
      this.resultsContainerTarget.parentElement.classList.remove("hidden");
    } else {
      this.resultsContainerTarget.classList.remove("hidden");
    }
  }

  searchTargetKeydown(e) {
    // Prevent enter key from submitting form when search results are open
    if (e.keyCode === 13 && !this.resultsContainerTarget.classList.contains("hidden")) {
      e.preventDefault();
    }
  }

  searchTargetKeyup(e) {
    if (e.keyCode === 38) {
      // up arrow
      this.focusPreviousResult();
      return false;
    }

    if (e.keyCode === 40) {
      // down arrow
      this.focusNextResult();
      return;
    }

    // enter
    if (e.keyCode === 13) {
      if (this.followLinkOnEnterValue) {
        window.location = this.searchResults[this.focusedResultIndexValue].link;
      } else {
        this.selectSearchResult(this.searchResults[this.focusedResultIndexValue]);
      }
      return;
    }

    const ignoredKeyModifier =
      e.keyCode === 16 || e.keyCode === 17 || e.keyCode === 18 || e.keyCode === 37 || e.keyCode === 39;

    const searchString = this.searchField.value.trim();

    const data =
      this.searchField.dataset.queryParam &&
      `search=${this.searchField.dataset.queryParam}&${
        this.searchField.dataset.queryParam
      }=${searchString}&exclude_id=${this.searchField.dataset.excludeId}&deal_memo=${
        this.searchField.dataset.dealMemo
      }${
        this.searchField.dataset.organizationId && `&organization_id=${this.searchField.dataset.organizationId}`
      }&person_only=${this.searchField.dataset.personOnly}&pipeline_id=${this.searchField.dataset.pipelineId}`;

    if (searchString.length >= this.minimumSearchLengthValue && !ignoredKeyModifier) {
      Rails.ajax({
        type: "GET",
        url: this.urlValue,
        data: data,
        error: this.showEmptySearchResults.bind(this),
        success: this.showSearchResults.bind(this),
      });
    }
  }

  selectSearchResultOnClick(ev) {
    const selectedResult = this.searchResults.find((result) => {
      return (
        `${result.id}` === `${ev.currentTarget.dataset.id}` && result.class_name === ev.currentTarget.dataset.class_name
      );
    });

    this.selectSearchResult(selectedResult);
  }

  populateSingleFormField(className, key, value) {
    let element = document.getElementsByName(`${className}[${key}]`)[0];
    if (element) {
      element.addEventListener("keyup", () => {
        document.getElementById("form-edited-warning")?.classList.remove("hidden");
      });
      element.value = value;
    }
  }

  reset() {
    this.idTargets.forEach((idTarget) => {
      idTarget.value = null;
      this.triggerChangeEvent(idTarget);
    });

    this.clearSearch();
    this.showEmptySearchResults();
    this.hideSearchResults();

    if (this.hasClassNameTarget) {
      this.classNameTarget.value = undefined;
    }

    if (document.getElementById("copy-last-deal-memo")) {
      document.getElementById("copy-last-deal-memo").classList.add("hidden");
      document.getElementById("copy-last-deal-memo-input").checked = false;
    }

    this.triggerBlurEvent(this.idTarget);
  }

  clearSearch() {
    if (!this.idTarget.value) {
      this.resetButtonTarget.classList.add("hidden");
      this.searchTarget.readOnly = false;
      this.searchTarget.value = "";
    }
  }

  triggerChangeEvent(element) {
    const event = new Event("change");
    element.dispatchEvent(event);
  }

  triggerBlurEvent(element) {
    const event = new Event("blur");
    element.dispatchEvent(event);
  }

  enableClosestSubmitButton() {
    if (this.hasEnableSubmitButtonValue && this.enableSubmitButtonValue) {
      const submitButton = this.element.closest('form').querySelector('input[type=submit]');
      if (submitButton) {
        submitButton.classList.remove('disabled');
      }
    }
  }

  selectSearchResult(selectedResult) {
    if (!selectedResult) {
      return;
    }

    document.getElementById("form-edited-warning")?.classList.add("hidden");
    document.getElementById("form-edited-warning")?.classList.add("hidden");

    if (this.hasResetButtonTarget) {
      this.resetButtonTarget.classList.remove("hidden");
    }
    this.searchTarget.readOnly = true;
    this.searchTarget.value = selectedResult.name;
    this.originalValue = selectedResult.name;

    this.idTargets.forEach((idTarget) => {
      idTarget.value = selectedResult.id;
      this.triggerChangeEvent(idTarget);
    });

    if (this.hasClassNameTarget) {
      this.classNameTarget.value = selectedResult.class_name;
    }

    if (this.hasResetButtonTarget) {
      this.resetButtonTarget.focus();
    }

    this.hideSearchResults();
    this.triggerBlurEvent(this.idTarget);
    this.enableClosestSubmitButton();

    if (this.submitOnSelectValue) {
      return this.element.closest("form").requestSubmit();
    }
  }

  showEmptySearchResults() {
    this.showSearchResults([]);
  }

  showSearchField() {
    this.displayElement.classList.add("hidden");
    this.searchField.classList.remove("hidden");
    this.searchField.value = "";
    this.searchField.focus();
  }

  showSearchResults(results) {
    this.updateSearchResults(results);
    this.unhideSearchResults();
  }

  focusPreviousResult() {
    this.focusedResultIndexValue =
      this.focusedResultIndexValue > 0 ? this.focusedResultIndexValue - 1 : this.searchResults.length - 1;

    this.focusResult();
  }

  focusNextResult() {
    this.focusedResultIndexValue =
      this.focusedResultIndexValue < this.searchResults.length - 1 ? this.focusedResultIndexValue + 1 : 0;

    this.focusResult();
  }

  focusResult() {
    var runningIndex = 0;

    this.resultsContainerTargets.forEach((resultsContainerTarget) => {
      resultsContainerTarget.querySelectorAll("div.result-item").forEach((result) => {
        if (runningIndex === this.focusedResultIndexValue) {
          result.classList.add("bg-primary-50", "text-primary-600");

          // If more than one resultsContainerTargets, scroll the grandparent
          const parentElement =
            this.resultsContainerTargets.length > 1 ? result.parentElement.parentElement : result.parentElement;

          parentElement.scroll({
            top: result.offsetTop - 40,
            behavior: "smooth",
          });
        } else {
          result.classList.remove("bg-primary-50", "text-primary-600");
        }

        runningIndex++;
      });
    });
  }

  splitName() {
    let inputString = this.searchField.value;

    // Extract email if present
    let email = '';
    const emailRegex = /<([^<>]+)>/;
    const emailMatch = inputString.match(emailRegex);
    if (emailMatch) {
      email = emailMatch[1]; // The captured group
      // Remove the email part from the input string
      inputString = inputString.replace(emailRegex, '').trim();
    }

    const nameArray = inputString.split(' ').filter(Boolean);

    let firstName = '';
    let middleName = '';
    let lastName = '';

    if (nameArray.length <= 1) {
      window.notyf.error('Please enter valid full name');
      return null;
    } else if (nameArray.length === 2) {
      firstName = nameArray[0];
      lastName = nameArray[1];
    } else if (nameArray.length >= 3) {
      firstName = nameArray[0];
      middleName = nameArray.slice(1, nameArray.length -1).join(' ');
      lastName = nameArray[nameArray.length -1];
    }

    return { firstName, middleName, lastName, email };
  }

  createPerson() {
    const nameParts = this.splitName();
    if (!nameParts) {
      return;
    }
    const { firstName, middleName, lastName, email } = nameParts;
    this.doCreateRequest(
      "/people",
      `person[first_name]=${encodeURIComponent(firstName)}&person[middle_name]=${encodeURIComponent(middleName)}&person[last_name]=${encodeURIComponent(lastName)}&person[email]=${encodeURIComponent(email)}&person[organization_id]=${this.searchField.dataset.organizationId}&searchbox=true`,
      `A person named ${this.searchField.value} will be created.`,
      "Person"
    );
  }

  createOrganization() {
    this.doCreateRequest(
      "/organizations",
      `organization[name]=${this.searchField.value}&organization[account_id]=${this.searchField.dataset.accountId}&searchbox=true`,
      `A new organization named ${this.searchField.value} will be created.`,
      "Organization"
    );
  }

  createAccountingEntity() {
    this.doCreateRequest(
      `/firm_admin/entities/${this.entityValue}/accounting_entities`,
      `firm_admin_accounting_entity[name]=${this.searchField.value}&searchbox=true`,
      `A new accounting entity named ${this.searchField.value} will be created.`,
      "FirmAdmin::AccountingEntity"
    );
  }

  createPortfolioCompany() {
    this.doCreateRequest(
      `/firm_admin/entities/${this.entityValue}/portfolio_companies/create_organization`,
      `firm_admin_portfolio_company[name]=${this.searchField.value}&searchbox=true`,
      `A new organization and portfolio company named ${this.searchField.value} will be created.`,
      "FirmAdmin::PortfolioCompany"
    );
  }

  doCreateRequest(url, data, confirmMessage, className) {
    if (!confirm(confirmMessage)) {
      return;
    }

    Rails.ajax({
      type: "POST",
      url: url,
      data: data,
      success: (model) => {
        this.selectSearchResult({
          ...model,
          class_name: className,
        });
      },
      error: (res) => window.notyf.error(res.errors),
    });
  }

  newCapitalAccountContactPerson() {
    const nameParts = this.splitName();
    this.doNewRequest(
      `/firm_admin/entities/${this.entityValue}/capital_account_contacts/new_person`,
      `person[first_name]=${nameParts.firstName}&person[middle_name]=${nameParts.middleName}&person[last_name]=${nameParts.lastName}`
    );
  }

  doNewRequest(url, data) {
    Rails.ajax({
      type: "POST",
      url: url,
      data: data,
      success: (response) => {
        this.containerTarget.innerHTML = response;
      },
      error: (res) => window.notyf.error(res.errors),
    });
  }

  updateSearchResults(results) {
    this.searchResults = results;
    this.focusedResultIndex = 0;

    this.resultsContainerTargets.forEach((resultsContainer) => {
      resultsContainer.querySelectorAll("div").forEach((result) => {
        result.remove();
      });
    });

    results.forEach((result) => {
      const resultsContainer = this.resultsContainerForResult(result);

      resultsContainer.insertAdjacentElement("beforeend", this.createSearchResult(result));
    });

    this.resultsContainerTargets.forEach((resultsContainer) => {
      if (resultsContainer.dataset.addPerson) {
        resultsContainer.insertAdjacentElement("beforeend", this.createAddPersonResult(resultsContainer));
      }

      if (resultsContainer.dataset.addOrganization) {
        resultsContainer.insertAdjacentElement("beforeend", this.createAddOrganizationResult(resultsContainer));
      }

      if (resultsContainer.dataset.addAccountingEntity) {
        resultsContainer.insertAdjacentElement("beforeend", this.createAddAccountingEntityResult());
      }

      if (resultsContainer.dataset.addPortfolioCompany) {
        resultsContainer.insertAdjacentElement("beforeend", this.createAddPortfolioCompanyResult());
      }
    });

    this.focusResult();
  }

  resultsContainerForResult(result) {
    const resultsContainer = this.resultsContainerTargets.find((resultsContainer) => {
      const classification = resultsContainer.dataset.classification;
      const classifyColumn = resultsContainer.dataset.classifyColumn || "class_name";

      return result[classifyColumn] === classification;
    });

    return resultsContainer || this.resultsContainerTarget;
  }

  submitFromResetButton(e) {
    if (e.keyCode === 13) {
      e.target.closest("form").requestSubmit();
      e.preventDefault();
    }
  }
}
