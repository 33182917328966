import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["input"];
  static values = { matchUsersPath: String };

  async connect() {
    await this.getUsersJson();
    const self = this;
    this.tagify = new Tagify(this.inputTarget, {
      whitelist: window.mentionsUsersList.map((user) => ({ value: user.id, name: user.name })),
      enforceWhitelist: true,
      keepInvalidTags: true,
      dropdown: {
        enabled: 1,
        classname: "users-list",
        searchKeys: ["name"],
        mapValueTo: "name",
      },
      tagTextProp: "name",
      autoComplete: { tabKey: true },
      hooks: {
        beforePaste(event, data) {
          if (data.pastedText.includes(",")) {
            self.handlePaste({ detail: { value: data.pastedText } });
            return new Promise((resolve, reject) => { false });
          }
          return new Promise((resolve, reject) => { resolve(); });
        },
      },
      pasteAsTags: false,
    });
  }

  clearInput(event) {
    this.tagify.removeAllTags();
  }

  async getUsersJson() {
    if (window.mentionsUsersList !== undefined) return;

    const res = await get(`/mentions.json`);
    const json = await res.json;
    window.mentionsUsersList = json;
  }

  async handlePaste(e) {
    const inputText = e.detail.value;
    const values = inputText.split(",").map((v) => v.trim());

    try {
      const response = await post(this.matchUsersPathValue, {
        body: JSON.stringify({ values: values }),
        responseKind: "json",
      });
      if (response.ok) {
        const matchedUsers = await response.json;
        this.addMatchedUsers(matchedUsers);
      } else {
        console.error("Failed to match users");
      }
    } catch (error) {
      console.error("Error matching users:", error);
    }
  }

  addMatchedUsers(users) {
    users.forEach((user) => {
      if (!this.tagify.isTagDuplicate(user.id) && !user.error) {
        this.tagify.settings.whitelist.push(user);
      }
      user.value = user.id;
      this.tagify.addTags([user]);
    });
  }
}
