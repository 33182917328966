import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["data", "showIcon", "hideIcon"];

  connect() {
    this.originalData = this.dataTarget.innerHTML.trim();
    this.hide();
  }

  toggle() {
    this.hidden ? this.reveal() : this.hide();
  }

  reveal() {
    this.hidden = false;
    this.dataTarget.innerHTML = this.originalData;
    this.showIconTarget.classList.add("!hidden");
    this.hideIconTarget.classList.remove("!hidden");
  }

  hide() {
    this.hidden = true;
    this.dataTarget.innerHTML = this.originalData.replace(/./g, "*");
    this.showIconTarget.classList.remove("!hidden");
    this.hideIconTarget.classList.add("!hidden");
  }
}
